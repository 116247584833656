import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
// import Week from "../components/week"
import MiniLayout from "../../components/minimalLayout"
import styles from "./project-library.module.css"

const categoryDict = {
  a: "Hello World / Low Bandwidth",
  b: "The Self",
  c: "Form & Aesthetic",
  d: "$$$ / Watched Over",
  e: "The Many / Virtual Worlds",
  f: "Online Intimacies",
}
function formatProjects(projectdb){
  let allCategories = [];

  let projects = projectdb.map(project=>{
    project.categories = Object.entries(project).filter(entry=>entry[1] == "x").map(entry=>entry[0].substring(0,1));
    console.log(project.categories)

    // add to allCategories, code from https://stackoverflow.com/a/1988361
    project.categories.forEach(category=> {
      allCategories.pushIfNotExist(category, function(e) { 
        return e === category; 
      });
    })

    project.date = project.yearUntil == null?project.yearFrom:project.yearFrom+project.yearUntil;
    let linkTexts = project.links.match(/(".*?"|[^\s",][^",]+[^\s",])(?=\s*,|\s*$)/g);
    linkTexts = linkTexts || [];
    linkTexts = linkTexts.map(str=>(str.startsWith('"')||str.endsWith('"'))?str.substring(1, str.length-1):str);
   
    // project.links2 = linkTexts;

    let linkURLs = project.linksLinks.match(/(https?:\/\/[^\s]+)/g);
    linkURLs = linkURLs || [];
    linkURLs = linkURLs.map(url=>url.endsWith(",")?url.substring(0, url.length-1):url)
    console.log(linkURLs);
    if(Math.abs(linkURLs.length-linkTexts.length)>0){
      console.log("links and text dont match", project);
      console.log("linkURLs", linkURLs);
      console.log("linkTexts", linkTexts);
    }
    
    project.linksPacks = []
    for(let i = 0; i < Math.min(linkURLs.length,linkTexts.length); i++){
      project.linksPacks.push( {url: linkURLs[i], text: linkTexts[i]} )
    }
    // project.links2 = linkTexts;


    return project
  });
  return {allCategories: allCategories, projects: projects}
  
}
// const ProjectLibrary = ({data}) => {
class ProjectLibrary extends React.Component{

  constructor(props) {
    // console.log(props);
    super(props);
    // console.log(props.data)
    this.data = formatProjects(props.data.googleSheet.fields.projectdb);
    // let p = this.state.projects.slice();
    this.data.projects.sort(() => Math.random() - 0.5);

    console.log("this.data", this.data)
    this.state = {
      allCategories: this.data.allCategories,
      projects: this.data.projects
    }
    // this.shuffle();
    // this.allCategories = this.data.allCategories;
    // this.projects = this.data.projects;

    // this.header = createRef();
    // this.artistsRef = createRef();
    // // this.state = { showMenu: true };
    this.listCategoryNames = this.listCategoryNames.bind(this)
    this.checkCategories = this.checkCategories.bind(this)
    this.listLinks = this.listLinks.bind(this)
    this.listProjects = this.listProjects.bind(this)
    this.shuffle = this.shuffle.bind(this)
    this.sortAlphabetically = this.sortAlphabetically.bind(this)
    this.sortChronologically = this.sortChronologically.bind(this)



    // this.degreeTracker = []
    // this.artistsYTracker = [];
    // this.move=true;
  }


  // console.log(data.googleSheet.fields.projectdb);
  // let {allCategories, projects} = formatProjects(data.googleSheet.fields.projectdb);
  // console.log(projects)
  

  listCategoryNames(){
    return this.state.allCategories.map((cat, i)=>{
      return(
        <div key={i} className={styles.catNameWrapperOuter}>
          <div className={styles.catNameWrapperInner}>
            <p onClick={()=>this.sortByCat(cat)}><span>{categoryDict[cat]}</span></p>
          </div>
        </div>
      )
    })
  }
  checkCategories(cats){
    return this.state.allCategories.map((cat, i)=>{
      if(cats.includes(cat)){
        return(
          <div key={i} className={styles.catDotWrapper}>
            <img src="../../blurry-point2.png"/>

          </div>
        )
      }else{
        return(
          <div key={i} className={styles.catDotWrapper}>
              <img className={styles.invisible} src="../../blurry-point2.png"/>
          </div>
        )
      }
      
    })
  }

  listLinks(links){
    if(links!=null&& links.length>0){
        let elms = [];
        links.forEach((link, index)=>{
            console.log(link)
            elms.push( <a key={index} className={styles.link} target={link.url.startsWith("http")?"_blank":""} href={link.url}>{link.text}</a>)
            if(index<links.length-1) elms.push( ", " )
        })
        return <p className={styles.allLinks}><span>Links:</span> {elms}</p>
        // return links.map((link, index)=><a key={index} className={styles.link} target="_blank" href={link.link}>{link.text}{links.length-index==1?"":", "}</a>)
    }else{
        return ""
    }
  }

  listProjects(){
    
    return this.state.projects.filter(project=>project.title!="DUMMY").map((project, i)=>{
      return(
        <div key={i} className={styles.projectWrapper}>
          
          <div className={styles.infoWrapper}>
            <p className={styles.date}>{project.date}</p>

            <p className={styles.title}>{project.title}</p>
            <p className={styles.artist}>by {project.artist}</p>
            <p className={styles.description}>{project.description}</p>
            
            {this.listLinks(project.linksPacks)}
          </div>
          <div className={styles.categoryWrapper}>
            {this.checkCategories(project.categories)}
          </div>
        </div>
      )
    })
  }

  shuffle(){
    // console.log("sas", this.state)
    // this.state.projects.sort(() => Math.random() - 0.5);
    let p = this.state.projects.slice();
    p.sort(() => Math.random() - 0.5);
    console.log(p)
    this.setState({
      projects: p
    })
    // let p = this.state.projects;
    // p.sort((a, b) => {
    //   return a.yearFrom-b.yearFrom
    //   let textA = a.title.toUpperCase();
    //   let textB = b.title.toUpperCase();
    //   return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    // });
    // this.setState({
    //   projects: p
    // })

  }

  sortChronologically(){
    let p = this.state.projects;
    p.sort((a, b) => {
      return a.yearFrom-b.yearFrom
      let textA = a.title.toUpperCase();
      let textB = b.title.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    this.setState({
      projects: p
    })
  }
  sortByCat(cat){
    let p = this.state.projects;
    p.sort((a, b) => {
      // return a.title-b.title
      let aContains = a.categories.includes(cat);
      let bContains = b.categories.includes(cat);
      return (aContains && !bContains) ? -1 : (!aContains && bContains) ? 1 : 0;
    });
    this.setState({
      projects: p
    })
  }
  sortAlphabetically(){
    
    let p = this.state.projects;
    p.sort((a, b) => {
      // return a.title-b.title
      let textA = a.title.toUpperCase();
      let textB = b.title.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
    this.setState({
      projects: p
    })
  }
 

  render(){
    

    return (
      <Layout>

        <MiniLayout>
          <div className={styles.verticalWrapper}>

            <div className={styles.introWrapper}>
              <h2>Project Library (in progress)</h2>
              <p>You can find projects for your inspiration below.</p>
              <p className={styles.disclaimer}><b>Disclaimer:</b> the categories are related to different modules of this course. Categorization is loose and may change in the future.</p>
            </div>

            <div className={styles.legendWrapper}>
              <div className={styles.legendLeft}>
                <p>Projects can be sorted <span className={styles.sortButton} onClick={this.sortAlphabetically}>by title</span> and <span className={styles.sortButton} onClick={this.sortChronologically}>by year</span>.</p>
                <p>Clicking on a category name, brings projects with that category to the top.</p>

              </div>
              <div className={styles.legendRight}>
                {this.listCategoryNames()}
              </div>

            </div>
            <div className={styles.contentWrapper}>
              {this.listProjects()}
              
            </div>

          </div>
        </MiniLayout>
      </Layout>
    )
  }
}



export default ProjectLibrary

export const query = graphql`
query {
  googleSheet {
    fields {
      projectdb {
        aHelloWorld__LowBandwidth
        active
        artist
        bTheSelf
        cForm__Aesthetic
        dMoney__WatchedOver
        eTheMany__VirtualWorlds
        fOnlineIntimacies
        links
        description
        linksLinks
        title
        type
        yearFrom
        yearUntil
      }
    }
  }
}
`


// var arr = str.match(/(".*?"|[^",\s]+)(?=\s*,|\s*$)/g);
// arr = arr.map(str=>(str.startsWith('"')||str.endsWith('"'))?str.substring(1, str.length-1):str);
// arr = arr || [];

// function urlify(text) {
  // var urlRegex = /(https?:\/\/[^\s]+)/g;
  // return text.replace(urlRegex, function(url) {
  //   return '<a href="' + url + '">' + url + '</a>';
  // })
  // or alternatively
  // return text.replace(urlRegex, '<a href="$1">$1</a>')
// }

// var text = 'Find me at http://www.example.com and also at http://stackoverflow.com';
// var html = urlify(text);





// from: https://stackoverflow.com/a/1988361

// check if an element exists in array using a comparer function
// comparer : function(currentElement)
Array.prototype.inArray = function(comparer) { 
  for(var i=0; i < this.length; i++) { 
      if(comparer(this[i])) return true; 
  }
  return false; 
}; 

// adds an element to the array if it does not already exist using a comparer 
// function
Array.prototype.pushIfNotExist = function(element, comparer) { 
  if (!this.inArray(comparer)) {
      this.push(element);
  }
}; 